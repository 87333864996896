import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'
const AppUrlListener = () => {
  const navigate = useNavigate()
  useEffect(() => {
    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      const slug = event.url.split(import.meta.env.VITE_WEB_APP_URL).pop()
      if (slug) {
        navigate(slug)
      }
    })
  }, [navigate])

  return null
}

export default AppUrlListener
