import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigation,
} from '@remix-run/react'

import type { LinksFunction } from '@remix-run/node'
import stylesheet from '~/tailwind.css?url'
import AppUrlListener from './components/AppUrlListener'
import useErrorBoundary from './hooks/use-error-boundary'

import aa from 'search-insights'
import { Toaster } from './components/ui/toaster'
import { useEffect } from 'react'
import { Haptics, ImpactStyle } from '@capacitor/haptics'

aa('init', {
  appId: import.meta.env.VITE_ALGOLIA_APP_ID,
  apiKey: import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY,
  useCookie: true,
})
export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
]
export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />

        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        {/* TODO: store google fonts locally? */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap"
          rel="stylesheet"
        />

        <Meta />
        <Links />
      </head>
      <body className='bg-[#0d131a] font-["Source_Sans_3"] text-white'>
        <AppUrlListener />
        <div className="flex h-screen flex-col items-stretch">{children}</div>
        <ScrollRestoration />
        <Scripts />
        <Toaster />
      </body>
    </html>
  )
}

export default function App() {
  const { state } = useNavigation()
  const { pathname } = useLocation()
  const hapticsFeedback = async () => {
    return await Haptics.impact({ style: ImpactStyle.Medium })
  }
  useEffect(() => {
    if (state === 'submitting') {
      hapticsFeedback()
    }
  }, [state])
  useEffect(() => {
    hapticsFeedback()
  }, [pathname])
  return <Outlet />
}
export function ErrorBoundary() {
  const { description, status, message } = useErrorBoundary()

  return (
    <div className="flex h-full flex-col items-center justify-center font-[Exo] text-white">
      <h1 className="font-[Exo] text-[#FC3A04] pt-safe-offset-4">
        {description}: {status}
      </h1>
      <div className="flex flex-col gap-6 px-2">
        <p>{message}</p>
        <Link
          to="/"
          className="rounded-full bg-sky-600 px-16 py-3.5 text-center font-semibold"
        >
          Back to Dashboard
        </Link>
      </div>
    </div>
  )
}
export function HydrateFallback() {
  return (
    <p className="flex h-full items-center justify-center font-[Exo] text-white">
      Loading...
    </p>
  )
}
